<template>
  <Modal gp-language-modal theme="my-white" @close="close" :dim-close="true">
    <ul class="lang-list" @click="click">
      <li v-for="item in itemList" :data-locale="item.value" :class="['lang-item', {'bottom-border' : item.border}, {'active' : item.value === currentLanguage}]">{{ item.label }}</li>
    </ul>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'LanguageModal',
  components: { Modal },
  data() {
    return {
      currentLanguage: null,
    };
  },
  computed: {
    langList: state('env', 'langList'),
    currentLocale() {
      return this.$i18n('locale');
    },
    itemList() {
      const length = this.langList.length;
      const isEven = length % 2 === 0;
      const last = isEven ? [length - 1, length - 2] : [length - 1];

      const list = this.langList.map((lang, idx) => {
        return {
          label: lang.label,
          value: lang.value,
          border: last.indexOf(idx) < 0,
        };
      });
      return list;
    }
  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
    click(e) {
      e.stopPropagation();
      const { target } = e;
      const { locale } = target.dataset;

      if (!locale) return;
      this.changeLang(locale);
    },
    changeLang(v) {
      this.$changeLocale(v);
      this.currentLanguage = v;
      this.close();
    }
  },
  mounted() {
    this.currentLanguage = this.currentLocale;
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[gp-language-modal] {
  > .dim {.bgc(rgba(0, 0, 0, 0.3))}
  .modal-title {.fs(16); font-weight: 600;}
  > .panel {.w(316); .h(100%); .abs(); .t(0); .r(0); border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; background-color: #fff !important; margin-right: 0 !important;

    @media (max-width: 316px) {
      .w(100%);
    }

    .header {.br(0);}
  }
  .lang-list {.flex(); flex-wrap: wrap;
    > .lang-item {.w(49%); .pv(10); .fs(14); .c(#737373); font-weight: 400; cursor: pointer;
      &.bottom-border {border-bottom: 1px solid #F4F4F5;}
      &.active {.c(#000); font-weight: 600;}
    }
  }
  @media (@tp-up) {
    > .panel {.w(316); .h(90%); .abs(); .t(45); right: calc(50% - 360px); border-bottom-right-radius: 16px !important; border-top-right-radius: 16px !important; background-color: #fff !important;
      .header {.br(0);}
    }
  }

}
</style>