<template>
  <div v-if="isLoad" my-info-handler :class="['field-wrapper', {'success': isSuccess}]">
    <PageHeader :has-lang-button="false" />
    <div class="handler-group">
      <FontIcon :name="icon" />
      <h3 class="handler-title" v-html="title" />
      <div v-if="desc" class="error-msg-wrapper">
        <p class="desc" v-html="desc"></p>
      </div>
    </div>
    <div class="bottom">
      <GpButton type="submit-green" @click="action">{{ $t('forgotPassword.label.openBrandId', { brandId: brandId }) }}</GpButton>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import PageHeader from '@/views/components/gg-pass/PageHeader.vue';

export default {
  name: 'MyInfoHandler',
  mixins: [fpErrorHandler],
  lexicon: 'myInfo.emailLinked',
  components: { PageHeader, GpButton, FontIcon },
  props: {
    structure: Specific,
  },
  data() {
    return {
      isLoad: false,
      success: false,
      modelInfo: {
        UserName: null,
        Token: null,
        Type: 'CHANGE_LOGIN_ID',
        Method: 'TOKEN',
        LoginMethod: '',
      }
    };
  },
  computed: {
    brandId: state('env', 'gpBrand'),
    site: state('env', 'site'),
    siteInfo: state('env', 'siteInfo'),
    mobile: state('browser', 'mobile'),
    csEmail: state('env', 'csEmail'),
    isSuccess() {
      return this.success;
    },
    icon() {
      return this.isSuccess ? 'check-over-circle-regular' : 'info-circle-line';
    },
    desc() {
      return this.error.errorMsg;
    },
    title() {
      return this.isSuccess ? this.$t('_.success', { method: this.$t('emailAddress'), csEmail: this.csEmail }) : this.$t('_.failed');
    },
  },
  methods: {
    async getVerificationCode() {
      const result = await this.$services.ggpass.getVerification(this.modelInfo.Token, this.modelInfo.Type);
      if (result.error) {
        this.apiErrorHandler(result);
        this.errorMsg = this.error.errorMsg;
        return false;
      }
      const statusResult = this.statusHandler(result.status);

      return statusResult;
    },
    async sendRequest() {
      const result = await this.$services.ggpass.verifyCode(this.modelInfo);
      if (result.error) {
        this.success = false;
        this.apiErrorHandler(result);
        this.errorMsg = this.error.errorMsg;
        return;
      }

      const statusResult = this.statusHandler(result.status);
      if (statusResult.error) {
        this.success = false;
        this.$t(statusResult.key);
      } else {
        this.success = true;
      }
    },
    action() {
      location.href = 'ggnetworks://action?OnePass';
    }
  },
  async mounted() {
    this.$store.commit('env/setTheme', 'white');
    const { verificationToken, userName, verificationType, testEmail } = this.$route.query;
    const checkedEmail = userName.includes('@');
    const replaceUserName = userName ? checkedEmail ? userName : `+${userName.trim()}` : null;
    this.modelInfo.Token = verificationToken || null;
    this.modelInfo.UserName = replaceUserName;
    this.modelInfo.Type = verificationType;

    if (!this.modelInfo.Token && !this.modelInfo.UserName) {
      await this.routeName('NotFound');
      return;
    }

    const result = await this.getVerificationCode();
    if (!result) {
      this.isLoad = true;
      return;
    }

    await this.sendRequest();
    this.$nextTick(() => {
      this.isLoad = true;
    });

  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info-handler] {.flex(); .flex-dc(); .justify-center(); .items-center(); .w(100vw); .h(100vh); .bgc(#f7f7f8);
  [gp-page-header] {.w(100%);}
  .handler-group {.flex(); .flex-dc(); flex: 2; .justify-center(); .items-center(); .w(100%);
    > .handler-title { .regular(); .fs(16); .m(24, 0, 12); .c(black); text-align: center;}
  }
  &.success {
    [font-icon] { .c(@gp-green); }
  }
  [font-icon] { .block(); .fs(40); .c(@c-red); }
  .error-msg-wrapper {.p(20); .w(100%); .mb(20);
    > p {.bgc(#fff); .c(#737373); .p(20);.br(8); .fs(14); text-align: center;}
  }
  .bottom {.w(100%); .p(20); .mt(40); flex: 1;}
}

</style>