<template>
  <div gp-page-header>
    <div class="main-header">
      <div class="left-header">
        <img :src="logo" alt="logo" class="page-logo" @click="click" />
      </div>
      <div class="right-header">
        <a v-if="isShowLanguage" @click="openLanguage">
          <!--          <FontIcon name="globe2-regular" />-->
          <FontIcon name="globe-regular" />
        </a>
        <a v-if="hasNotification" @click="$emit('openNoti')">
          <FontIcon name="bell" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import BackButton from '@/views/components/gg-pass/BackButton.vue';
import Specific from '@shared/types/Specific';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import LanguageModal from '@/views/components/gg-pass/modal/LanguageModal.vue';

export default {
  name: 'PageHeader',
  components: { SubHeader, BackButton, FontIcon },
  props: {
    rootPath: { type: String, default: '/', },
    hasNotification: { type: Boolean, default: false, },
    hasLangButton: { type: Boolean, default: true }
  },
  computed: {
    logo: state('env', 'logo'),
    site: state('env', 'site'),
    isShowLanguage() {
      return this.site !== siteIds.TWOACE && this.hasLangButton;
    }
  },
  methods: {
    click() {
      this.replaceRouteName(this.rootPath);
    },
    openLanguage() {
      this.$modal(LanguageModal);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-page-header] { .bgc(#333333);
  .page-logo { .h(26);}
  .main-header { .flex(); .p(0, 16, 0, 16); .h(48); .space-between(); .items-center(); .bgc(#333333); .max-w(720); .w(100%); margin: auto;
    .right-header { line-height: 0; .fs(20); .pt(4); }
  }
  .locale-select {display: none}
  .right-header {.wh(100%); .flex(); .items-center(); .justify-end(); .gap(20); }
  .left-header { cursor: pointer; }
}

</style>
